import './Introduction.css';
import Timeline from './Timeline/Timeline';

function Introduction(){
    return(
        <>
            <h1>
                Introduction
            </h1>
            <div className="introduction">
                <div className="intro-text">
                    <ul>
                        <li>I am from Taipei, Taiwan.</li>
                        <li>I came to Japan at 2016 as a research student at Nagoya University.</li>
                        <li>Studied Public Finance (undergraduate) and did research on Economic Statistics (graduate) when I was a student.</li>

                        <Timeline />
      
                        <li>I finished my education at 2017, and started to work at 2018.
                            <ul>
                                <li>Participated in Web App development, RPA implementation projects in a medium-sized Japanese consulting company.</li>
                                <li>Currently doing Windows App development, Web App development in a financial services company.</li>
                            </ul> 
                        </li>
                        <li>I can speak Japanese, English, Chinese, Taiwanese and a little bit Korean.</li>
                    </ul>   
                </div>
                <div className="intro-image-block">
                    <div className="intro-image-grid-container">
                        <img alt="Ken" src="/static/images/ice_selling.jpg" className="intro-image" />
                    </div>
                    <div className="intro-image-grid-container">
                        <img alt="Ken" src="/static/images/zoo.jpg" className="intro-image" />
                    </div>
                    <div className="intro-image-grid-container">
                        <img alt="Ken" src="/static/images/job_searching.jpg" className="intro-image" />
                    </div>
                </div>
            </div>
            <h2 className="intro-greeting">
                Wish you have a nice day!
            </h2>   
        </>
    );
}

export default Introduction;