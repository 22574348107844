import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import './ThemeCard.css';

export default function ThemeCard(props) {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea href={props.href}>
        <CardMedia
          component="img"
          height="140"
          image={props.imageUrl}
          alt={props.theme}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.theme}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.discrip}
          </Typography>
        </CardContent>       
      </CardActionArea>
      <CardActions>
        <Button size="small" href={props.href}>Learn More</Button>
      </CardActions>
    </Card>
  );
}
