import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import './Timeline.css';

export default function OppositeContentTimeline() {
  return (
    <React.Fragment>
      <Timeline>
        
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            2014 ~ 2017
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>Chengchi University <br/> Dept. of Economics, Master of Arts.</TimelineContent>
        </TimelineItem>
        
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            2016 ~ 2017
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>Nagoya University <br/> Research Program, NUPACE</TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            2009 ~ 2013
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>Taipei University <br/> Dept. of Public Finance, Bachelor of Arts.</TimelineContent>
        </TimelineItem>

      </Timeline>
    </React.Fragment>
  );
}