import './Work.css';

function Work(){
    return(
        <>
            <h1>
                Work
            </h1>
            <div className="work">
                <div className="work-text">
                    <ul>
                        <li className="work-Experience">Experience
                            <ul>
                                <li className="no-bullet">Economic Statistics research with Stata, R Languages.</li>
                                <li className="no-bullet">Web App development with Java, JS, CSS.</li>  
                                <li className="no-bullet">Windows Forms Application Development with C#, .NET Framework.</li>                               
                                <li className="no-bullet">RPA integration projects.</li>
                                <li className="no-bullet">System implementation consulting.</li>                                                         
                            </ul>
                        </li>   
                    </ul>
                </div>
            </div>           
        </>
    );
}

export default Work;