import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import Box from '@mui/material/Box';
import ImageListItem from '@mui/material/ImageListItem';
import './StandardImageList.css';

export default function StandardImageList() {
  return (
    <Box sx={{ width: 500, height: 475, overflowY: 'scroll' }}>
      <ImageList variant="masonry" cols={2} gap={8}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}

const itemData = [
  {
    img: '/static/images/Life.jpg',
    title: 'Life',
  },
  {
    img: '/static/images/dance.jpg',
    title: 'dance',
  },
  {
    img: '/static/images/running.jpg',
    title: 'running',
  },
  {
    img: '/static/images/Plant-Nanny.jpg',
    title: 'Plant-Nanny',
  },
  {
    img: '/static/images/Introduction.jpg',
    title: 'Introduction',
  },
  {
    img: '/static/images/kouzu-island.jpg',
    title: 'kouzu-island',
  },
  {
    img: '/static/images/K.jpg',
    title: 'K',
  },
  {
    img: '/static/images/rockman.png',
    title: 'rockman',
  },
];
