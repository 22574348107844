import React from "react";
import {
  Box,
} from "./FooterStyles";
import './Footer.css';
  
const Footer = () => {
  return (
    <Box>
      <h1 style={{ color: "green", 
                   textAlign: "center", 
                   marginTop: "-50px" }}>
        Powered by Firebase and React library of JavaScript.
      </h1>
    </Box>
  );
};
export default Footer;