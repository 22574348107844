import './App.css';
import NavBar from './NavBar/NavBar';
import TopPage from './TopPage/TopPage';

function App() {
  return (
    <div className="App">
    
      <header className="App-header">
        <NavBar/>
      </header>

      <body>
        <TopPage/>
      </body>
    
    </div>
  );
}

export default App;
