import ThemeCard from './ThemeCard/ThemeCard';
import Introduction from './Introduction/Introduction';
import Life from './Life/Life';
import Work from './Work/Work';
import Footer from './Footer/Footer';
import './TopPage.css';
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import {db} from '../firebase';
import { useState } from 'react';

// DB Connection
async function getcollection() {

    const collectionRef = collection(db, "sentences");
    const q = query(collectionRef, orderBy("created_by", "desc"), limit(1))
    const querySnapshot = await getDocs(q);
    console.log(querySnapshot.docs[0].data());
    return querySnapshot.docs[0].data();  
}

function TopPage() {

    // shows the value in Firestore
    const [sentence,setSentence] = useState("")
    getcollection().then(latestSentence => setSentence(latestSentence));

    return (
        <>
            <div className="intro-block">
                <div className="image-block">
                    <img alt="Ken" src="/static/images/Ken.jpg" className="image" />
                </div>
                <div className="text">
                    <p className="gretting">Hi!</p>
                    <p className="who-is-k">I am Hsu Peng Kai. <br/> My English name is Ken.</p>
                </div>
            </div>

            <div className="theme-block">
                <ThemeCard imageUrl="/static/images/Introduction.jpg" theme="Introduction" discrip="Here are some basic information about me &#128570;" href="#Introduction" />
                <ThemeCard imageUrl="/static/images/Life.jpg" theme="Life" discrip="Photos and descriptions about my hobbies and activities besides work." href="#Life" />
                <ThemeCard imageUrl="/static/images/Work.jpg" theme="Work" discrip="Work and job in my daily life." href="#Work" />
            </div>

            <h2 className="sentence-today no-bold">
                An interesting sentence today: <br/> 
            </h2>
            <h2 className="sentence-today">
                {sentence.sentence_in_english}<br/>
            </h2>
            <h2 className="sentence-today">
                {sentence.sentence_in_chinese}<br/>
            </h2>
            <h2 className="sentence-today no-bold">
                (by {sentence.author_in_english})
            </h2>

            <hr></hr>

            <div id="Introduction">
                <Introduction />
            </div>

            <div id="Life">
                <Life />
            </div>

            <div id="Work">
                <Work />
            </div>

            <div id="Footer">
                <Footer />
            </div>
        </>
    );
}

export default TopPage;
 
