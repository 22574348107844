import './Life.css';
import StandardImageList from './StandardImageList/StandardImageList';

function Life(){
    return(
        <>
            <h1>
                Life
            </h1>
            <div className="life">

                <div className="life-images">
                    <StandardImageList />
                </div>

                <div className="life-text">
                    <ul>
                        <li className="no-bullet"> &#127952; I like volleyball and played it for 4 years in my college life. It was a good memory.</li>
                        <li className="no-bullet"> &#127955; I also like table tennis pretty much.</li>
                        <li className="no-bullet"> &#127926; I joined pop dance club at Taipei University and Nagoya University. Pop dance is cool!</li>
                        <li className="no-bullet"> &#127939; Running is one of my hobbies. I participated in the 2019 Osaka Marathon and completed the full marathon (by almost 7 hours...).</li>
                        <li className="no-bullet"> &#127984; Walking around unknown cities and places is my hobby, too.</li>
                        <li className="no-bullet"> &#128241; I feel trying unknown apps is interesting recently.</li>
                    </ul>   
                </div>
            </div>           
        </>
    );
}

export default Life;